import PersonToInstitutionRelationEntity from "../Entities/PersonToInstitutionRelationEntity";
import PersonToInstitutionRelationModel from "../models/PersonToInstitutionRelationModel";
import Network from "../network/network";
import Logger from "../util/Logger";
import BaseService from "./BaseService";
import ContactMediaService from "./ContactMediaService";

export default class PersonToInstitutionRelationService extends BaseService<PersonToInstitutionRelationEntity> {
    private SERVICE_NAME = "PersonToInstitutionRelationService";
    public static ODATA_ROUTE = "personToInstitution";

    private readonly contactMediaService: ContactMediaService;

    constructor(network: Network, contactMediaService: ContactMediaService, logger: Logger) {
        super(network, logger);
        this.contactMediaService = contactMediaService;
    }

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return PersonToInstitutionRelationService.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): PersonToInstitutionRelationEntity {
        return PersonToInstitutionRelationEntity.fromOdata(dto, new PersonToInstitutionRelationEntity());
    }

    public getPersonToInstitutionRelationsByEmail = async (email: string): Promise<PersonToInstitutionRelationModel[]> => {
        return this.getRelations(`Contacts/any(d:d/NameInMedia eq '${email}')`);
    };

    public getRelationByPersonInstitutionId = async (personId: number, institutionId: number): Promise<PersonToInstitutionRelationModel[]> => {
        return this.getRelations(`PersonId eq ${personId} and InstitutionId eq ${institutionId}`);
    };

    public getRelationsByPersonId = async (personId: number): Promise<PersonToInstitutionRelationModel[]> => {
        return this.getRelations(`PersonId eq ${personId}`);
    };

    public getRelationsByInstitutionId = async (institutionId: number): Promise<PersonToInstitutionRelationModel[]> => {
        return this.getRelations(`InstitutionId eq ${institutionId}`);
    };

    public getRelations = async (filter: string): Promise<PersonToInstitutionRelationModel[]> => {
        const pitr = await this.getItems(filter);
        const contactMediaTypes = await this.contactMediaService.getItems();
        return pitr.map(e => new PersonToInstitutionRelationModel(e, contactMediaTypes));
    };

    public getRelationsCountByPersonId = async (personId: number): Promise<number> => {
        return await this.getCount(`PersonId eq ${personId}`);
    };

    public getRelationsCountByInstitutionId = async (institutionId: number): Promise<number> => {
        return await this.getCount(`InstitutionId eq ${institutionId}`);
    };

    protected getSelects = (): string[] => {
        return ["PersonId", "InstitutionId", "RelationId", "IsMainInstitution"];
    };

    protected getExpands = (): string[] => {
        return ["Person($select=DisplayName)", "Institution($select=Name)", "Relation", "Contacts($select=NameInMedia,TranslatedDisplayName,MediaId)"];
    };
}
