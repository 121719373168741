import BaseAddressEntity from "./BaseAddressEntity";

export default class InstitutionAddressEntity extends BaseAddressEntity {
    private _institutionId: number;

    get InstitutionId(): number {
        return this._institutionId;
    }

    set InstitutionId(value: number) {
        this._institutionId = value;
    }

    static fromOdata(odataItem: any, entity: InstitutionAddressEntity): InstitutionAddressEntity {
        super.fromOdata(odataItem, entity);
        entity.InstitutionId = odataItem["InstitutionId"];
        return entity;
    }
}
