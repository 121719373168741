import BaseService from "./BaseService";
import PersonAddressEntity from "../Entities/PersonAddressEntity";

export default class PersonAddressService extends BaseService<PersonAddressEntity> {
    private SERVICE_NAME = "PersonAddressService";
    private ODATA_ROUTE = "personAddresses";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): PersonAddressEntity {
        return PersonAddressEntity.fromOdata(dto, new PersonAddressEntity());
    }

    public getAddressesByPersonId = async (personId: number): Promise<PersonAddressEntity[]> => {
        return await this.getItems(`PersonId eq ${personId}`);
    };
}
