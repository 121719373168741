import BaseEntity from "./BaseEntity";

export default class ContactMediaEntity extends BaseEntity {
    private _name: string;
    private _mediaType: string;
    private _translatedDisplayName: string;
    private _inputType: string;

    public get Name(): string {
        return this._name;
    }

    public set Name(value: string) {
        this._name = value;
    }

    public get MediaType(): string {
        return this._mediaType;
    }

    public set MediaType(value: string) {
        this._mediaType = value;
    }

    public get TranslatedDisplayName(): string {
        return this._translatedDisplayName;
    }

    public set TranslatedDisplayName(value: string) {
        this._translatedDisplayName = value;
    }

    public get InputType(): string {
        return this._inputType;
    }

    public set InputType(value: string) {
        this._inputType = value;
    }

    static fromOdata(odataItem: any, entity: ContactMediaEntity): ContactMediaEntity {
        super.fromOdata(odataItem, entity);
        entity.Name = odataItem["Name"];
        entity.MediaType = odataItem["MediaType"];
        entity.TranslatedDisplayName = odataItem["TranslatedDisplayName"];
        entity.InputType = odataItem["InputType"];
        return entity;
    }
}
