import Network from "../network/network";

export default class Logger {
    private static readonly LOGGER_SOURCE = "OutlookPlugin";
    private network: Network;

    constructor(network: Network) {
        this.network = network;
    }

    public log(level: Loglevel, msg: string, trace?: string) {
        if (level === Loglevel.Info || level === Loglevel.Warning) {
            console.log(`%c ${level} - ${msg}\n`, `color: ${this.getColor(level)}`, trace);
        } else if (level === Loglevel.Error) {
            console.error(`%c ${level} - ${msg}\n`, `color: ${this.getColor(level)}`, trace);
            this.network.sendApi("writelog", this.getOdataLogObject(Loglevel.Error, `${msg} ${trace}`));
        }
    }

    private getColor = (level: Loglevel) => {
        switch (level) {
            case Loglevel.Error:
                return "#FF2121";
            case Loglevel.Warning:
                return "#FFFF21";
            case Loglevel.Info:
                return "";
        }
    };

    private getOdataLogObject(logLevel: Loglevel, message: string): any {
        return {
            Source: Logger.LOGGER_SOURCE,
            Level: Logger.getLevelAsString(logLevel),
            Message: message,
            Timestamp: new Date().toISOString(),
        };
    }

    private static getLevelAsString(logLevel: Loglevel): string {
        switch (logLevel) {
            case Loglevel.Error:
                return "Error";
            case Loglevel.Warning:
                return "Warn";
            case Loglevel.Info:
                return "Info";
        }
    }
}

export enum Loglevel {
    Info = "Info",
    Warning = "Warning",
    Error = "Error",
}
