import BaseEntity from "./BaseEntity";

export default class PersonToInstRelationTypeEntity extends BaseEntity {
    private _translatedDisplayName: string;

    public constructor(init?: Partial<PersonToInstRelationTypeEntity>) {
        super();
        Object.assign(this, init);
    }

    public get TranslatedDisplayName(): string {
        return this._translatedDisplayName;
    }

    public set TranslatedDisplayName(value: string) {
        this._translatedDisplayName = value;
    }

    static fromOdata(odataItem: any, entity: PersonToInstRelationTypeEntity): PersonToInstRelationTypeEntity {
        super.fromOdata(odataItem, entity);
        entity.TranslatedDisplayName = odataItem["TranslatedDisplayName"];
        return entity;
    }
}
