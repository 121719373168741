import BaseAddressEntity from "./BaseAddressEntity";

export default class PersonAddressEntity extends BaseAddressEntity {
    private _personId: number;

    public get PersonId(): number {
        return this._personId;
    }

    public set PersonId(value: number) {
        this._personId = value;
    }

    static fromOdata(odataItem: any, entity: PersonAddressEntity): PersonAddressEntity {
        super.fromOdata(odataItem, entity);
        entity.PersonId = odataItem["PersonId"];
        return entity;
    }
}
