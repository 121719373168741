import PersonEntity from "../Entities/PersonEntity";
import PersonModel from "../models/personModel";
import Network from "../network/network";
import { EntityTypeId } from "../util/EntityTypeId";
import Logger, { Loglevel } from "../util/Logger";
import Util from "../util/Util";
import AvatarService from "./AvatarService";
import BaseService from "./BaseService";
import ContactMediaService from "./ContactMediaService";
import PersonAddressService from "./PersonAddressService";
import PersonContactService from "./PersonContactService";

export default class PersonService extends BaseService<PersonEntity> {
    private readonly contactService: PersonContactService;
    private readonly personAddressService: PersonAddressService;
    private readonly avatarService: AvatarService;
    private readonly contactMediaTypeService: ContactMediaService;

    private SERVICE_NAME = "PersonService";
    private ODATA_ROUTE = "people";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): PersonEntity {
        return PersonEntity.fromOdata(dto, new PersonEntity());
    }

    constructor(
        network: Network,
        contactMediaService: PersonContactService,
        personAddressService: PersonAddressService,
        avatarService: AvatarService,
        contactMediaTypeService: ContactMediaService,
        logger: Logger
    ) {
        super(network, logger);
        this.contactService = contactMediaService;
        this.personAddressService = personAddressService;
        this.avatarService = avatarService;
        this.contactMediaTypeService = contactMediaTypeService;
    }

    public getPersonModelById = async (id: number): Promise<PersonModel> => {
        const personDto: any = Util.firstOrDefault(await this.getItems(`Id eq ${id}`));
        if (personDto) {
            const person = PersonEntity.fromOdata(personDto, new PersonEntity());
            const contactMedia = await this.contactService.getContactMedia(person.Id);
            const personAddress = await this.personAddressService.getAddressesByPersonId(person.Id);
            const contactMediaTypes = await this.contactMediaTypeService.getItems();
            const personModel = new PersonModel(person, contactMedia, personAddress, contactMediaTypes);
            personModel.Avatar = await this.avatarService.getAvatar(person.Id, EntityTypeId.PersonEntity);
            return personModel;
        }
        return null;
    };

    public findPersonByEmailAddress = async (email: string): Promise<number> => {
        try {
            const items = await this.network.requestApi(`${this.ODATA_ROUTE}?$filter=Contacts/any(contact:contact/NameInMedia eq '${email}')&$select=Id`);
            const item = Util.firstOrDefault(items.value);
            if (item) {
                return item["Id"];
            }
            return null;
        } catch (e) {
            this.logger.log(Loglevel.Error, `Could not fetch id for institution`, e);
        }
    };

    protected getExpands = (): string[] => {
        return ["Akquise", "Ansprechspartner"];
    };
}
