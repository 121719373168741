export default class DateTime {
    static parseNumberDate = (date: Date): string => {
        return `${DateTime.needsLeadingZero(date.getDate())}.${DateTime.needsLeadingZero(date.getMonth() + 1)}.${date.getFullYear()}`;
    };

    static parseNumberDateTime = (date: Date) => {
        return `${DateTime.needsLeadingZero(date.getDate())}.${DateTime.needsLeadingZero(date.getMonth() + 1)}.${date.getFullYear()} ${DateTime.getTime(date)}`;
    };

    private static getTime(date: Date): string {
        return `${DateTime.needsLeadingZero(date.getHours())}:${DateTime.needsLeadingZero(date.getMinutes())}`;
    }

    private static needsLeadingZero = (numberToCheck: number): string => {
        return numberToCheck.toString().length === 1 ? `0${numberToCheck}` : numberToCheck.toString();
    };
}
