import BaseEntity from "./BaseEntity";

export default class BaseContactEntity extends BaseEntity {
    private _note: string;
    private _isPrefered: boolean;
    private _mediaId: number;
    private _nameInMedia: string;
    private _alias: string;
    private _translatedDisplayName: string;

    public get MediaId(): number {
        return this._mediaId;
    }

    public set MediaId(value: number) {
        this._mediaId = value;
    }

    public get NameInMedia(): string {
        return this._nameInMedia;
    }

    public set NameInMedia(value: string) {
        this._nameInMedia = value;
    }

    public get Alias(): string {
        return this._alias;
    }

    public set Alias(value: string) {
        this._alias = value;
    }

    public get Note(): string {
        return this._note;
    }

    public set Note(value: string) {
        this._note = value;
    }

    public get IsPrefered(): boolean {
        return this._isPrefered;
    }

    public set IsPrefered(value: boolean) {
        this._isPrefered = value;
    }

    public get TranslatedDisplayName(): string {
        return this._translatedDisplayName;
    }

    public set TranslatedDisplayName(value: string) {
        this._translatedDisplayName = value;
    }

    static fromOdata(odataItem: any, entity: BaseContactEntity): BaseContactEntity {
        super.fromOdata(odataItem, entity);

        entity.Note = odataItem["Note"] ?? "";
        entity.IsPrefered = odataItem["IsPrefered"];
        entity.MediaId = odataItem["MediaId"];
        entity.NameInMedia = odataItem["NameInMedia"] ?? "";
        entity.Alias = odataItem["Alias"] ?? "";
        entity.TranslatedDisplayName = odataItem["TranslatedDisplayName"] ?? "";
        return entity;
    }
}
