import SearchEntity from "../Entities/SearchEntity";
import Network from "../network/network";
import { EntityTypeId } from "../util/EntityTypeId";
import Logger, { Loglevel } from "../util/Logger";

export default class SearchService {
    private SERVICE_NAME = "SearchService";
    public static API_ROUTE = "Search";

    private readonly network: Network;
    private readonly logger: Logger;

    constructor(network: Network, logger: Logger) {
        this.network = network;
        this.logger = logger;
    }

    public mapDtoToEntity(dto: any): SearchEntity {
        return SearchEntity.fromOdata(dto, new SearchEntity());
    }

    public getSearchResult = async (searchRequest: string): Promise<SearchEntity[]> => {
        try {
            let result = await (
                await this.network.sendPublicApi(`${SearchService.API_ROUTE}`, {
                    search: searchRequest,
                    entityTypesToSearch: [EntityTypeId.InstitutionEntity, EntityTypeId.PersonEntity],
                })
            ).json();
            return result.map(r => this.mapDtoToEntity(r)).filter(r => r.EntityTypeId === EntityTypeId.PersonEntity || r.EntityTypeId === EntityTypeId.InstitutionEntity);
        } catch (e) {
            this.logger.log(Loglevel.Error, `${this.SERVICE_NAME} could not get searchresult for searchQuery: ${searchRequest}`, e);
        }
    };
}
