import AkquiseTypeEntity from "../Entities/AkquiseTypeEntity";
import ContactNoteEntity from "../Entities/ContactNoteEntity";
import DateTime from "../util/DateTime";
import LeadModel from "./LeadModel";

export default class ContactNoteModel extends kendo.data.ObservableObject {
    private readonly _contactNote: ContactNoteEntity;
    private readonly _akquiseType: AkquiseTypeEntity;
    private _detailVisible: boolean;
    private _leadModel: LeadModel;
    private _isBoundToMail: boolean;

    constructor(contactNote: ContactNoteEntity, akquiseType: AkquiseTypeEntity, leadModel: LeadModel) {
        super();
        this._contactNote = contactNote;
        this._akquiseType = akquiseType;
        this._leadModel = leadModel;
        super.init(this);
    }

    get Uuid() {
        return this._contactNote.Uuid;
    }

    get Type(): string {
        return this._akquiseType.TranslatedDisplayName;
    }

    get Note(): string {
        return $("<textarea />").html(this._contactNote.Note).text() ?? "";
    }

    get HasNote(): boolean {
        return !!this.Note;
    }

    get Subject(): string {
        return this._contactNote.Subject ?? "";
    }

    get Mood(): string {
        switch (this._contactNote.Mood) {
            case "Happy":
                return "<i class='smiley-happy'>";
            case "Neutral":
                return "<i class='smiley-neutral'>";
            case "Unhappy":
                return "<i class='smiley-sad'>";
            default:
                return "<i class='smiley-happy'>";
        }
    }

    get Date(): Date {
        return new Date(this._contactNote.Date);
    }

    get FormatedDateWithType(): string {
        return DateTime.parseNumberDate(this.Date) + " (" + this.Type + ")";
    }

    get CreatedAt(): Date {
        return new Date(this._contactNote.CreatedAt);
    }

    get HasLeadModel(): boolean {
        return !!this.LeadModel;
    }

    get LeadModel(): LeadModel {
        return this._leadModel;
    }

    get DetailVisible(): boolean {
        return this._detailVisible;
    }

    set DetailVisible(val: boolean) {
        this._detailVisible = val;
    }

    public toggleDetail = e => {
        this.set("DetailVisible", !this.get("DetailVisible"));
    };

    get IsBoundToMail(): boolean {
        return this._isBoundToMail;
    }

    set IsBoundToMail(val: boolean) {
        this._isBoundToMail = val;
    }
}
