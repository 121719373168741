import SenderModel from "../models/SenderModel";
import InstitutionService from "./InstitutionService";
import PersonService from "./PersonService";
import PersonToInstitutionRelationService from "./PersonToInstitutionRelationService";
import Util from "../util/Util";

export default class SenderModelService {
    private readonly personService: PersonService;
    private readonly institutionService: InstitutionService;
    private readonly relationService: PersonToInstitutionRelationService;

    constructor(personService: PersonService, institutionService: InstitutionService, relationService: PersonToInstitutionRelationService) {
        this.personService = personService;
        this.institutionService = institutionService;
        this.relationService = relationService;
    }

    public getSenderModel = async (personId: number, institutionId: number): Promise<SenderModel> => {
        const person = await this.personService.getPersonModelById(personId);
        const institution = await this.institutionService.getInstitutionModelById(institutionId);
        const relationContacts = Util.firstOrDefault(await this.relationService.getRelationByPersonInstitutionId(personId, institutionId)).Contacts;
        const countOfPersons = await this.relationService.getRelationsCountByPersonId(personId);
        const countOfInstitutions = await this.relationService.getRelationsCountByInstitutionId(institutionId);
        return new SenderModel(person, institution, relationContacts, countOfPersons, countOfInstitutions);
    };

    public getPersonSenderModelById = async (id: number): Promise<SenderModel> => {
        const personModel = await this.personService.getPersonModelById(id);
        const countOfPersons = await this.relationService.getRelationsCountByPersonId(id);
        return new SenderModel(personModel, null, null, countOfPersons, null);
    };

    public getInstitutionSenderModel = async (id: number): Promise<SenderModel> => {
        const institutionModel = await this.institutionService.getInstitutionModelById(id);
        const countOfInstitutions = await this.relationService.getRelationsCountByInstitutionId(id);
        return new SenderModel(null, institutionModel, null, null, countOfInstitutions);
    };
}
