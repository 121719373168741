import ContactNoteModel from "./contactNoteModel";
import InstitutionModel from "./InstitutionModel";
import LeadModel from "./LeadModel";
import PersonModel from "./personModel";
import TodoModel from "./TodoModel";
import RelationContactModel from "./relationModel";

export default class SenderModel {
    private readonly _person: PersonModel;
    private readonly _institution: InstitutionModel;
    private _leads: LeadModel[] = [];
    private _todos: TodoModel[] = [];
    private _contactNotes: ContactNoteModel[] = [];
    private _personCounter: number;
    private _institutionCounter: number;
    private _relationContacts: RelationContactModel;

    constructor(person: PersonModel, institution: InstitutionModel, relationContacts: RelationContactModel, countOfPerson: number, countOfInstitution: number) {
        this._person = person;
        this._institution = institution;
        this._personCounter = countOfPerson;
        this._institutionCounter = countOfInstitution;
        this._relationContacts = relationContacts;
    }

    get Leads(): LeadModel[] {
        return this._leads;
    }

    set Leads(val: LeadModel[]) {
        this._leads = val;
    }

    get Todos(): TodoModel[] {
        return this._todos;
    }

    set Todos(val: TodoModel[]) {
        this._todos = val;
    }

    get Person(): PersonModel {
        return this._person;
    }

    get HasPerson(): boolean {
        return !!this.Person;
    }

    get Institution(): InstitutionModel {
        return this._institution;
    }

    get HasInstitution(): boolean {
        return !!this.Institution;
    }

    get ContactNotes(): ContactNoteModel[] {
        return this._contactNotes;
    }

    set ContactNotes(val: ContactNoteModel[]) {
        this._contactNotes = val;
    }

    get RelationContacts(): RelationContactModel {
        return this._relationContacts;
    }

    get PersonAvatar(): string {
        return this.HasPerson && this.Person.HasAvatar ? this.Person.Avatar : "/assets/icon-64.png";
    }

    get InstitutionAvatar(): string {
        return this.HasInstitution && this.Institution.HasAvatar ? this.Institution.Avatar : "/assets/icon-64.png";
    }

    get PersonCounter(): number {
        return this._personCounter;
    }

    set PersonCounter(value: number) {
        this._personCounter = value;
    }

    get InstitutionCounter(): number {
        return this._institutionCounter;
    }

    set InstitutionCounter(value: number) {
        this._institutionCounter = value;
    }

    get HasPersonCounter(): boolean {
        return this.HasPerson && !!this.PersonCounter && this.PersonCounter >= 1;
    }

    get HasInstitutionCounter(): boolean {
        return this.HasInstitution && !!this.InstitutionCounter && this.InstitutionCounter >= 1;
    }

    get HasRelationContactData(): boolean {
        return !!this._relationContacts;
    }

    get Title(): string {
        if (this.HasPerson && this.HasInstitution) {
            return this.Person.DisplayName + " (" + this.Institution.DisplayName + ")";
        } else if (this.HasPerson) {
            return this.Person.DisplayName;
        } else if (this.HasInstitution) {
            return this.Institution.DisplayName;
        } else {
            return "Orphy CRM mobile";
        }
    }
}
