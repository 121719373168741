import BaseEntity from "./BaseEntity";

export default class CompanyEntity extends BaseEntity {
    private _name: string;

    public get Name(): string {
        return this._name;
    }

    public set Name(value: string) {
        this._name = value;
    }

    static fromOdata(odataItem: any, entity: CompanyEntity): CompanyEntity {
        super.fromOdata(odataItem, entity);
        entity.Name = odataItem["Name"];
        return entity;
    }
}
