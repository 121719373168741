import BaseService from "./BaseService";
import InstitutionAddressEntity from "../Entities/InstitutionAddressEntity";

export default class InstitutionAddressService extends BaseService<InstitutionAddressEntity> {
    private SERVICE_NAME = "InstitutionAddressService";
    private ODATA_ROUTE = "institutionAddresses";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): InstitutionAddressEntity {
        return InstitutionAddressEntity.fromOdata(dto, new InstitutionAddressEntity());
    }

    public getAddressesByInstitutionID = async (institutionId: number): Promise<InstitutionAddressEntity[]> => {
        return await this.getItems(`InstitutionId eq ${institutionId}`);
    };
}
