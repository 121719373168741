import Entity from "./Entity";

export default abstract class BaseEntity extends Entity {
    private _id: number;
    private _uuid: string;

    public get Id(): number {
        return this._id;
    }

    public set Id(value: number) {
        this._id = value;
    }

    public set Uuid(val: string) {
        this._uuid = val;
    }

    public get Uuid(): string {
        return this._uuid;
    }

    static fromOdata(odataItem: any, entity: BaseEntity): BaseEntity {
        entity.Id = odataItem["Id"];
        entity.Uuid = odataItem["Uuid"];
        return entity;
    }

    public toODataObject(): Object {
        return {};
    }
}
