import TodoEntity from "../Entities/TodoEntity";
import Network from "../network/network";
import Logger, { Loglevel } from "../util/Logger";
import BaseService from "./BaseService";

export default class TodoService extends BaseService<TodoEntity> {
    private readonly SERVICE_NAME = "TodoService";
    public static ODATA_ROUTE = "todo";

    constructor(network: Network, logger: Logger) {
        super(network, logger);
    }

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    public getOdataRoute(): string {
        return TodoService.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): TodoEntity {
        return TodoEntity.fromOdata(dto, new TodoEntity());
    }

    public save = async (contactNote: TodoEntity): Promise<TodoEntity> => {
        try {
            const saved = await this.network.sendApi(this.getOdataRoute(), TodoEntity.toOdataObject(contactNote));
            return this.mapDtoToEntity(saved);
        } catch (e) {
            this.logger.log(Loglevel.Error, "Unable to save contactnote", e);
        }
    };

    public linkPersonToInstitutionEntry = async (todoId: number, personId: number, institutionId: number): Promise<void> => {
        this.network.sendApi(`${this.getOdataRoute()}(${todoId})/PersonToInstitutionRelations/$ref`, {
            "@odata.id": `${Network.OdataBaseURL}PersonToInstitution(${personId}, ${institutionId})`,
        });
    };

    public linkInstitutionEntry = async (todoId: number, institutionId: number): Promise<void> => {
        this.network.sendApi(`${this.getOdataRoute()}(${todoId})/Institutions/$ref`, {
            "@odata.id": `${Network.OdataBaseURL}Institution(${institutionId})`,
        });
    };

    public linkPersonEntry = async (todoId: number, personId: number): Promise<void> => {
        this.network.sendApi(`${this.getOdataRoute()}(${todoId})/Persons/$ref`, {
            "@odata.id": `${Network.OdataBaseURL}People(${personId})`,
        });
    };

    protected getExpands = (): string[] => {
        return ["Persons($select=Id,DisplayName)", "Institutions($select=Id,Name)", "PersonToInstitutionRelations($expand=Person($select=Id,DisplayName), Institution($select=Id,Name))"];
    };
}
