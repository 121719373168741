import BaseEntity from "./BaseEntity";

export default class UserEntity extends BaseEntity {
    private _displayName: string;
    private _personId: number;
    private _departmentId: number;
    private _mitarbeiterCode: string;

    public get DisplayName(): string {
        return this._displayName;
    }

    public set DisplayName(value: string) {
        this._displayName = value;
    }

    public get PersonId(): number {
        return this._personId;
    }

    public set PersonId(value: number) {
        this._personId = value;
    }

    public get DepartmentId(): number {
        return this._departmentId;
    }

    public set DepartmentId(value: number) {
        this._departmentId = value;
    }

    public set MitarbeiterCode(value: string) {
        this._mitarbeiterCode = value;
    }

    public get MitarbeiterCode(): string {
        return this._mitarbeiterCode;
    }

    static fromOdata(odataItem: any, entity: UserEntity): UserEntity {
        super.fromOdata(odataItem, entity);
        entity.DisplayName = odataItem["DisplayName"];
        entity.PersonId = odataItem["PersonId"];
        entity.DepartmentId = odataItem["DepartmentId"];
        entity.MitarbeiterCode = odataItem["MitarbeiterCode"];
        return entity;
    }
}
