import BaseContactEntity from "./BaseContactEntity";

export default class InstitutionContactEntity extends BaseContactEntity {
    private _institutionId: number;

    public get InstitutionId(): number {
        return this._institutionId;
    }

    public set InstitutionId(value: number) {
        this._institutionId = value;
    }

    static fromOdata(odataItem: any, entity: InstitutionContactEntity): InstitutionContactEntity {
        super.fromOdata(odataItem, entity);
        entity.InstitutionId = odataItem["InstitutionId"];
        return entity;
    }
}
