import BaseAddressEntity from "../Entities/BaseAddressEntity";
import BaseContactEntity from "../Entities/BaseContactEntity";
import ContactMediaEntity from "../Entities/ContactMediaEntity";
import PersonEntity from "../Entities/PersonEntity";
import Network from "../network/network";
import ContactBaseModel from "./ContactBaseModel";
import ContactModel from "./ContactModel";

export default class PersonModel extends ContactBaseModel {
    private readonly _person: PersonEntity;
    private _avatar: string;

    constructor(person: PersonEntity, contactMedia: BaseContactEntity[], address: BaseAddressEntity[], contactMediaTypes: ContactMediaEntity[]) {
        super(contactMedia, address, contactMediaTypes);
        this._person = person;
    }

    get Id(): number {
        return this._person.Id;
    }

    get CustomId(): string {
        return this._person.CustomId;
    }

    get OrphyLink(): string {
        return `${Network.BaseURL}PersonDetail/Details/${this._person.Id}`;
    }

    get DisplayName(): string {
        return this._person.DisplayName;
    }

    get Avatar(): string {
        return this._avatar;
    }

    set Avatar(val: string) {
        this._avatar = val;
    }

    get HasAvatar(): boolean {
        return !!this.Avatar;
    }

    get HasContactPerson(): boolean {
        return !!this._person.Ansprechspartner;
    }

    get ContactPerson(): ContactModel {
        if (this.HasContactPerson) {
            return new ContactModel(this._person.Ansprechspartner.DisplayName, `${Network.BaseURL}PersonDetail/Details/${this._person.Ansprechspartner.PersonId}`);
        }
        return null;
    }
}
