import AkquiseTypeEntity from "../Entities/AkquiseTypeEntity";
import CachedBaseService from "./CachedBaseService";

export default class AkquiseTypeService extends CachedBaseService<AkquiseTypeEntity> {
    private SERVICE_NAME = "AkquiseTypeService";
    private ODATA_ROUTE = "akquisetypes";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): AkquiseTypeEntity {
        return AkquiseTypeEntity.fromOdata(dto, new AkquiseTypeEntity());
    }
}
