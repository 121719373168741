import Network from "../network/network";
import { EntityTypeId } from "../util/EntityTypeId";
import Logger, { Loglevel } from "../util/Logger";

export default class AvatarService {
    private SERVICE_NAME = "AvatarService";
    private API_ROUTE = "Avatar";

    private readonly network: Network;
    private readonly logger: Logger;

    constructor(network: Network, logger: Logger) {
        this.network = network;
        this.logger = logger;
    }

    public getAvatar = async (id: number, entityTypeId: EntityTypeId): Promise<string> => {
        try {
            var isIE11 = !!window["MSInputMethodContext"] && !!(document as any).documentMode;
            if (isIE11) {
                //base64 by fetch for ie11 has different size (may encoding) and get interpreted as application/octet-stream, hack for ie use static icons
                if (entityTypeId === EntityTypeId.PersonEntity) {
                    return "https://app.orphy.com/content/images/buttonicons/empty_people.jpg";
                } else if (entityTypeId === EntityTypeId.InstitutionEntity) {
                    return "https://app.orphy.com/content/images/buttonicons/empty_industry.jpg";
                } else {
                    return "https://app.orphy.com/content/images/buttonicons/empty_task.jpg";
                }
            } else {
                const avatar = await this.network.requestPublicApi(`${this.API_ROUTE}?id=${id}&typeId=${entityTypeId}&width=50&height=50&roundCorners=False`);
                return this.getDataURL(await avatar.blob());
            }
        } catch (e) {
            this.logger.log(Loglevel.Error, `Could not get avatar for id: ${id} with typeId: ${entityTypeId}`, e);
        }
    };

    private getDataURL = (blob: Blob): Promise<string> => {
        return new Promise(res => {
            const r = new FileReader();
            r.onload = img => res(img.target.result as string);
            r.readAsDataURL(blob);
        });
    };
}
