/**
 *
 * Default utility class should only hold static methods
 *
 */

export default class Util {
    static firstOrDefault<T>(items: T[]): T {
        if (Array.isArray(items) && items.length >= 1) {
            return items[0];
        }
        return null;
    }

    static isNumeric(value): boolean {
        return value == Number(value);
    }

    static convertOdataValuesToObject(value) {
        return value.value;
    }

    static async foreachAsync<TItem, TResult>(items: TItem[], itemCallback: (item: TItem) => Promise<TResult>): Promise<TResult[]> {
        return Promise.all(items.map(async item => await itemCallback(item)));
    }

    static tryParseJSON(val: string, defaultVal: any) {
        try {
            return JSON.parse(val);
        } catch (e) {
            return defaultVal;
        }
    }

    static showLoading(elementName: string = "main") {
        kendo.ui.progress($(elementName), true);
    }

    static hideLoading(elementName: string = "main") {
        kendo.ui.progress($(elementName), false);
    }

    static stringContains(target: string, contains: string): boolean {
        return target.indexOf(contains) > -1;
    }

    static getEmptyAvatar(): string {
        return "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    }
}
