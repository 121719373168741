import BaseEntity from "./BaseEntity";

export default class LegalFormEntity extends BaseEntity {
    private _name: string;
    private _type: number;
    private _translatedDisplayName: string;

    public get Name(): string {
        return this._name;
    }

    public set Name(value: string) {
        this._name = value;
    }

    public get Type(): number {
        return this._type;
    }

    public set Type(value: number) {
        this._type = value;
    }

    public get TranslatedDisplayName(): string {
        return this._translatedDisplayName;
    }

    public set TranslatedDisplayName(value: string) {
        this._translatedDisplayName = value;
    }

    static fromOdata(odataItem: any, entity: LegalFormEntity): LegalFormEntity {
        super.fromOdata(odataItem, entity);
        entity.Name = odataItem["Name"];
        entity.Type = odataItem["Type"];
        entity.TranslatedDisplayName = odataItem["TranslatedDisplayName"];
        return entity;
    }
}
