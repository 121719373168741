import BaseService from "./BaseService";
import PersonContactEntity from "../Entities/PersonContactEntity";

export default class PersonContactService extends BaseService<PersonContactEntity> {
    private SERVICE_NAME = "ContactMediaService";
    private ODATA_ROUTE = "personcontact";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): PersonContactEntity {
        return PersonContactEntity.fromOdata(dto, new PersonContactEntity());
    }

    public getContactMedia = async (personId: number): Promise<PersonContactEntity[]> => {
        return await this.getItems(`PersonId eq ${personId}`);
    };

    public insertContactMedia = async (): Promise<void> => {
        return null;
    };
}
