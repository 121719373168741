import ContactMediaEntity from "../Entities/ContactMediaEntity";
import CachedBaseService from "./CachedBaseService";

export default class ContactMediaService extends CachedBaseService<ContactMediaEntity> {
    private SERVICE_NAME = "ContactMediaService";
    private ODATA_ROUTE = "ContactMedia";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): ContactMediaEntity {
        return ContactMediaEntity.fromOdata(dto, new ContactMediaEntity());
    }
}
