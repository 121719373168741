module.exports = function () {
    switch (ENV_ENDPOINT) {
        case "app":
            return {
                OUTLOOK_REDIRECT: "https://outlookplugin.orphy.com/",
                ORPHY_URL: "app.orphy.com",
                API: "api.orphy.com",
                IDSRV: "idsrv.orphy.com",
            };
        case "test":
            return {
                OUTLOOK_REDIRECT: "https://outlookplugintest.orphy.com/",
                ORPHY_URL: "app.orphy.com",
                API: "api.orphy.com",
                IDSRV: "idsrv.orphy.com",
            };
        case "local":
            return {
                OUTLOOK_REDIRECT: "https://localhost:3000/",
                ORPHY_URL: "80-niggi.cluyo.ch",
                API: "80-niggi.cluyo.ch/orphyapi",
                IDSRV: "dev.idsrv.orphy.com",
            };
        case "local-app":
            return {
                OUTLOOK_REDIRECT: "https://localhost:3000/",
                ORPHY_URL: "app.orphy.com",
                API: "api.orphy.com",
                IDSRV: "idsrv.orphy.com",
            };
        case "local-dev":
            return {
                OUTLOOK_REDIRECT: "https://localhost:3000/",
                ORPHY_URL: "dev.orphy.com",
                API: "dev.api.orphy.com",
                IDSRV: "dev.idsrv.orphy.com",
            };
    }
};
