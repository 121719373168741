import CurrencyEntity from "../Entities/CurrencyEntity";
import LeadEntity from "../Entities/LeadEntity";
import Network from "../network/network";
import DateTime from "../util/DateTime";

export default class LeadModel extends kendo.data.ObservableObject {
    private readonly _lead: LeadEntity;
    private readonly _currencies: CurrencyEntity[];
    private _avatar: string;
    private _detailVisible: boolean;

    constructor(lead: LeadEntity, currencies: CurrencyEntity[]) {
        super();
        this._lead = lead;
        this._currencies = currencies;
        super.init(this);
    }

    get Id(): number {
        return this._lead.Id;
    }

    get Avatar(): string {
        return this._avatar;
    }

    set Avatar(val: string) {
        this._avatar = val;
    }

    get Title(): string {
        return this._lead.Titel;
    }

    get DisplayTitle(): string {
        return this.Title.length > 36 ? this.Title.substr(0, 36) + "..." : this.Title;
    }

    get OrphyLeadLink(): string {
        return `${Network.BaseURL}LeadDetail/Details/${this._lead.Id}`;
    }

    get HasPerson(): boolean {
        return !!this._lead.Person;
    }

    get HasInstitution(): boolean {
        return !!this._lead.Institution;
    }

    get PersonName(): string {
        return this._lead.Person?.DisplayName;
    }

    get HasDueDate(): boolean {
        return !!this._lead.DueDate;
    }

    get DueDate(): string {
        return DateTime.parseNumberDate(new Date(this._lead.DueDate));
    }

    get OrphyPersonLink(): string {
        return `${Network.BaseURL}PersonDetail/Details/${this._lead.Person?.Id}`;
    }

    get Price(): string {
        return `${this._lead.Price} ${this._currencies.find(c => c.Id === this._lead.CurrencyId).Symbol}`;
    }

    get ContactPersonName(): string {
        return this._lead.ContactPerson.DisplayName;
    }

    get ContactPersonLink(): string {
        return `${Network.BaseURL}PersonDetail/Details/${this._lead.ContactPerson.Id}`;
    }

    get InstitutionName(): string {
        return this._lead.Institution?.Name;
    }

    get OrphyInstitutionLink(): string {
        return `${Network.BaseURL}InstitutionDetail/Details/${this._lead.Institution?.Id}`;
    }

    get OverAllState(): string {
        return this._lead.OverallState ? this._lead.OverallState.toString() : "";
    }

    get State(): string {
        return this._lead.State.toString();
    }

    get DetailVisible(): boolean {
        return this._detailVisible;
    }

    set DetailVisible(val: boolean) {
        this._detailVisible = val;
    }

    public toggleDetail = e => {
        this.set("DetailVisible", !this.get("DetailVisible"));
    };
}
