import Entity from "./Entity";
import PersonToInstRelationTypeEntity from "./PersonToInstRelationTypeEntity";
import ContactNoteEntity from "./ContactNoteEntity";
import BaseContactEntity from "./BaseContactEntity";

export default class PersonToInstitutionRelationEntity extends Entity {
    private _personId: number;
    private _institutionId: number;
    private _relation: PersonToInstRelationTypeEntity;
    private _relationId: number;
    private _isMainInstitution: boolean;

    private _personDisplayName: string;
    private _institutionDisplayName: string;

    private _contactNote: ContactNoteEntity[];
    private _contacts: BaseContactEntity[];

    public get PersonId(): number {
        return this._personId;
    }

    public set PersonId(value: number) {
        this._personId = value;
    }

    public get InstitutionId(): number {
        return this._institutionId;
    }

    public set InstitutionId(value: number) {
        this._institutionId = value;
    }

    public get RelationId(): number {
        return this._relationId;
    }

    public set RelationId(value: number) {
        this._relationId = value;
    }

    public set PersonDisplayName(val: string) {
        this._personDisplayName = val;
    }

    public get PersonDisplayName(): string {
        return this._personDisplayName;
    }

    public set InstitutionDisplayName(val: string) {
        this._institutionDisplayName = val;
    }

    public get InstitutionDisplayName(): string {
        return this._institutionDisplayName;
    }

    public get Relation(): PersonToInstRelationTypeEntity {
        return this._relation;
    }

    public set Relation(value: PersonToInstRelationTypeEntity) {
        this._relation = value;
    }

    public get ContactNote(): ContactNoteEntity[] {
        return this._contactNote;
    }

    public set ContactNote(value: ContactNoteEntity[]) {
        this._contactNote = value;
    }

    public get IsMainInstitution(): boolean {
        return this._isMainInstitution;
    }

    public set IsMainInstitution(value: boolean) {
        this._isMainInstitution = value;
    }

    public get Contacts(): BaseContactEntity[] {
        return this._contacts;
    }

    public set Contacts(value: BaseContactEntity[]) {
        this._contacts = value;
    }

    static fromOdata(odataItem: any, entity: PersonToInstitutionRelationEntity): PersonToInstitutionRelationEntity {
        entity.PersonId = odataItem["PersonId"];
        entity.InstitutionId = odataItem["InstitutionId"];
        entity.Relation = odataItem["Relation"] ? PersonToInstRelationTypeEntity.fromOdata(odataItem["Relation"], new PersonToInstRelationTypeEntity()) : null;
        entity.RelationId = odataItem["RelationId"];
        entity.InstitutionDisplayName = odataItem["Institution"] ? odataItem["Institution"]["Name"] : null;
        entity.PersonDisplayName = odataItem["Person"] ? odataItem["Person"]["DisplayName"] : null;
        entity.ContactNote = odataItem["Akquise"] ? odataItem["Akquise"].map(a => ContactNoteEntity.fromOdata(a, new ContactNoteEntity())) : null;
        entity.IsMainInstitution = odataItem["IsMainInstitution"] ? odataItem["IsMainInstitution"] : false;
        entity.Contacts = odataItem["Contacts"] ? odataItem["Contacts"].map(a => BaseContactEntity.fromOdata(a, new BaseContactEntity())) : null;
        return entity;
    }
}
