import CurrencyEntity from "../Entities/CurrencyEntity";
import LeadEntity from "../Entities/LeadEntity";
import LeadModel from "../models/LeadModel";
import Network from "../network/network";
import Util from "../util/Util";
import Logger, { Loglevel } from "../util/Logger";
import BaseService from "./BaseService";
import CurrencyService from "./CurrencyService";

export default class LeadService extends BaseService<LeadEntity> {
    private SERVICE_NAME = "LeadService";
    public static ODATA_ROUTE = "lead";

    private readonly currencyService: CurrencyService;
    private currencyEntityCache: CurrencyEntity[] = [];

    constructor(network: Network, currencyService: CurrencyService, logger: Logger) {
        super(network, logger);
        this.currencyService = currencyService;
    }

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return LeadService.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): LeadEntity {
        return LeadEntity.fromOdata(dto, new LeadEntity());
    }

    public getPersonLeads = async (personId: number): Promise<LeadModel[]> => {
        return await this.getLeads(`PersonId eq ${personId}`);
    };

    public getInstitutionLeads = async (institutionId: number): Promise<LeadModel[]> => {
        return await this.getLeads(`InstitutionId eq ${institutionId}`);
    };

    public getLeadById = async (id: number) => {
        return Util.firstOrDefault(await this.getLeads(`Id eq ${id}`));
    };

    public getLeads = async (filter: string = ""): Promise<LeadModel[]> => {
        const currencies = await this.getCurrencies();
        const leadModels = (await this.getItems(filter)).map(le => new LeadModel(le, currencies));
        return leadModels;
    };

    public save = async (lead: LeadEntity): Promise<LeadEntity> => {
        try {
            const saved = await this.network.sendApi(this.getOdataRoute(), LeadEntity.toOdataObject(lead));
            return this.mapDtoToEntity(saved);
        } catch (e) {
            this.logger.log(Loglevel.Error, "Unable to save lead", e);
        }
    };

    private getCurrencies = async () => {
        if (!this.currencyEntityCache.length) {
            this.currencyEntityCache = await this.currencyService.getItems();
        }
        return this.currencyEntityCache;
    };

    protected getExpands = (): string[] => {
        return ["ContactPerson", "Institution", "Person"];
    };
}
