import BaseEntity from "./BaseEntity";

export default class CurrencyEntity extends BaseEntity {
    private _code: string;
    private _symbol: string;
    private _isMainCurrency: boolean;

    public get Code(): string {
        return this._code;
    }

    public set Code(value: string) {
        this._code = value;
    }

    public get Symbol(): string {
        return this._symbol;
    }

    public set Symbol(value: string) {
        this._symbol = value;
    }

    public get IsMainCurrency(): boolean {
        return this._isMainCurrency;
    }

    public set IsMainCurrency(value: boolean) {
        this._isMainCurrency = value;
    }

    static fromOdata(odataItem: any, entity: CurrencyEntity): CurrencyEntity {
        super.fromOdata(odataItem, entity);
        entity.Code = odataItem["Code"];
        entity.Symbol = odataItem["Symbol"];
        entity.IsMainCurrency = odataItem["IsMainCurrency"];
        return entity;
    }
}
