const config = require("../../env")();

export default abstract class Network {
    private static readonly PROTOCOL = "https://";
    protected static readonly OUTLOOK_REDIRECT = config.OUTLOOK_REDIRECT;
    protected static readonly BASE_URL = `${Network.PROTOCOL}${config.ORPHY_URL}/`;
    protected static readonly API_BASE_URL = `${Network.PROTOCOL}${config.API}/api/v1/`;
    protected static readonly ODATA_BASE_URL = `${Network.PROTOCOL}${config.API}/odata/v3/`;
    protected static readonly IDSRV_URL = `${Network.PROTOCOL}${config.IDSRV}`;

    protected static readonly TOKEN_ENDPOINT = "Orphy";

    public abstract authenticate(): Promise<object>;
    public abstract signout();

    public requestPublicApi = async (urlEnd: string): Promise<Response> => {
        return this.request(`${Network.API_BASE_URL}${urlEnd}`);
    };

    public requestApi = async (urlEnd: string): Promise<any> => {
        const request = await this.request(`${Network.ODATA_BASE_URL}${urlEnd}`);
        return request.json();
    };

    protected request = async (url: string): Promise<Response> => {
        const headers = await this.getHeaders();
        return fetch(url, {
            method: "GET",
            headers: headers,
        });
    };

    public sendPublicApi = async (urlEnd: string, data: any = {}): Promise<any> => {
        return this.send(`${Network.API_BASE_URL}${urlEnd}`, data);
    };

    public sendApi = async (urlEnd: string, data: any = {}, method: string = "POST"): Promise<any> => {
        return this.send(`${Network.ODATA_BASE_URL}${urlEnd}`, data, method);
    };

    protected send = async (url: string, data: any = {}, method: string = "POST"): Promise<any> => {
        const headers = await this.getHeaders();
        if (method === "PATCH") {
            headers.append("Prefer", "return=representation");
        }
        const request = await fetch(url, {
            method: method,
            headers: headers,
            body: JSON.stringify(data),
        });
        if (request.status > 400) {
            throw new Error(`request failed with status ${request.status}`);
        }
        try {
            return await request.json();
        } catch (e) {
            // request has no json available
            return request;
        }
    };

    protected getHeaders = async (): Promise<Headers> => {
        const headers = new Headers();
        headers.append("Authorization", this.getAuthorizationHeader(await this.authenticate()));
        headers.append("content-type", "application/json");
        headers.append("pragma", "no-cache");
        headers.append("cache-control", "no-cache");
        return headers;
    };

    public getAuthorizationHeader = (token: any): string => {
        return `${token.token_type} ${token.access_token}`;
    };

    public static get BaseURL(): string {
        return Network.BASE_URL;
    }

    public static get APIBaseURL(): string {
        return Network.API_BASE_URL;
    }

    public static get OdataBaseURL(): string {
        return Network.ODATA_BASE_URL;
    }
}
