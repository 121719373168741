import BaseEntity from "./BaseEntity";

export default class AkquiseTypeEntity extends BaseEntity {
    private _name: string;
    private _akquiseContactType: string;
    private _translatedDisplayName: string;

    get Name(): string {
        return this._name;
    }

    set Name(value: string) {
        this._name = value;
    }

    get AkquiseContactType(): string {
        return this._akquiseContactType;
    }

    set AkquiseContactType(value: string) {
        this._akquiseContactType = value;
    }

    get TranslatedDisplayName(): string {
        return this._translatedDisplayName;
    }

    set TranslatedDisplayName(value: string) {
        this._translatedDisplayName = value;
    }

    static fromOdata(odataItem: any, entity: AkquiseTypeEntity): AkquiseTypeEntity {
        super.fromOdata(odataItem, entity);
        entity.Name = odataItem["Name"];
        entity.AkquiseContactType = odataItem["AkquiseContactType"];
        entity.TranslatedDisplayName = odataItem["TranslatedDisplayName"];
        return entity;
    }
}
