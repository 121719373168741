import ContactNoteEntity from "../Entities/ContactNoteEntity";
import Network from "../network/network";
import Logger, { Loglevel } from "../util/Logger";
import BaseService from "./BaseService";

export default class AkquiseService extends BaseService<ContactNoteEntity> {
    private SERVICE_NAME = "AkquiseService";
    public static ODATA_ROUTE = "ContactNotes";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return AkquiseService.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): ContactNoteEntity {
        return ContactNoteEntity.fromOdata(dto, new ContactNoteEntity());
    }

    constructor(network: Network, logger: Logger) {
        super(network, logger);
    }

    public save = async (contactNote: ContactNoteEntity): Promise<ContactNoteEntity> => {
        try {
            let method = "POST";
            let route = this.getOdataRoute();
            if (contactNote.Id && contactNote.Id > 0) {
                route += `(${contactNote.Id})`;
                method = "PATCH";
            }
            const saved = await this.network.sendApi(route, ContactNoteEntity.toOdataObject(contactNote), method);
            return this.mapDtoToEntity(saved);
        } catch (e) {
            this.logger.log(Loglevel.Error, "Unable to save contactnote", e);
        }
    };

    public linkSynchronizedAccount = async (contactNoteId: number, account: string, itemId: string): Promise<void> => {
        try {
            await this.network.sendApi(`${this.getOdataRoute()}/CreateSyncSourceReference(Id=${contactNoteId},Account='${account}',ItemId='${itemId}')`);
        } catch (e) {
            this.logger.log(Loglevel.Info, "No account linked -> ignore");
            Promise.resolve();
        }
    };

    public linkPersonToInstitutionEntry = async (contactNoteId: number, personId: number, institutionId: number): Promise<void> => {
        this.network.sendApi(`${this.getOdataRoute()}(${contactNoteId})/PersonToInstitutionRelations/$ref`, {
            "@odata.id": `${Network.OdataBaseURL}PersonToInstitution(${personId}, ${institutionId})`,
        });
    };

    public linkInstitutionEntry = async (contactNoteId: number, institutionId: number): Promise<void> => {
        this.network.sendApi(`${this.getOdataRoute()}(${contactNoteId})/Institutions/$ref`, {
            "@odata.id": `${Network.OdataBaseURL}Institution(${institutionId})`,
        });
    };

    public linkPersonEntry = async (contactNoteId: number, personId: number): Promise<void> => {
        this.network.sendApi(`${this.getOdataRoute()}(${contactNoteId})/Persons/$ref`, {
            "@odata.id": `${Network.OdataBaseURL}People(${personId})`,
        });
    };
}
