import ContactMediaEntity from "../Entities/ContactMediaEntity";
import PersonToInstitutionRelationEntity from "../Entities/PersonToInstitutionRelationEntity";
import BaseModel from "../viewModels/BaseModel";
import RelationContactModel from "./relationModel";

export default class PersonToInstitutionRelationModel {
    private personToInstitutionRelation: PersonToInstitutionRelationEntity;
    private readonly contactMediaTypes: ContactMediaEntity[];
    private _avatar;

    constructor(personToInstitutionRelation: PersonToInstitutionRelationEntity, contactMediaTypes: ContactMediaEntity[]) {
        this.personToInstitutionRelation = personToInstitutionRelation;
        this.contactMediaTypes = contactMediaTypes;
    }

    get PersonName(): string {
        return this.personToInstitutionRelation.PersonDisplayName;
    }

    get InstitutionName(): string {
        return this.personToInstitutionRelation.InstitutionDisplayName;
    }

    get PersonId(): number {
        return this.personToInstitutionRelation.PersonId;
    }

    get InstitutionId(): number {
        return this.personToInstitutionRelation.InstitutionId;
    }

    get Avatar(): string {
        return this._avatar;
    }

    set Avatar(val: string) {
        this._avatar = val;
    }

    get RelationName(): string {
        if (this.InstitutionName) {
            return `${this.personToInstitutionRelation.Relation.TranslatedDisplayName} ${BaseModel.t("by")} ${this.InstitutionName}`;
        } else {
            return BaseModel.t("private");
        }
    }

    get IsMainInstitution(): boolean {
        return this.personToInstitutionRelation.IsMainInstitution;
    }

    get Contacts(): RelationContactModel {
        const model = new RelationContactModel(this.personToInstitutionRelation.Contacts, null, this.contactMediaTypes);
        model.RelationName = `${BaseModel.t("contact-data-with")} ${this.InstitutionName}`;
        return model;
    }
}
