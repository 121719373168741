import BaseEntity from "./BaseEntity";
import ContactNoteEntity from "./ContactNoteEntity";
import UserEntity from "./UserEntity";

export default class PersonEntity extends BaseEntity {
    private _displayName: string;
    private _initials: string;
    private _customId: string;
    private _title: string;
    private _firstName: string;
    private _lastName: string;
    private _birthday: string;
    private _sex: string;
    private _salutationId: number;
    private _avatar: string;
    private _ansprechspartnerId: number;
    private _akquise: ContactNoteEntity[];
    private _ansprechsPartner: UserEntity;

    public get Initials(): string {
        return this._initials;
    }

    public set Initials(value: string) {
        this._initials = value;
    }

    public get CustomId(): string {
        return this._customId;
    }

    public set CustomId(value: string) {
        this._customId = value;
    }

    public get Title(): string {
        return this._title;
    }

    public set Title(value: string) {
        this._title = value;
    }

    public get FirstName(): string {
        return this._firstName;
    }

    public set FirstName(value: string) {
        this._firstName = value;
    }

    public get LastName(): string {
        return this._lastName;
    }

    public set LastName(value: string) {
        this._lastName = value;
    }

    public get Birthday(): string {
        return this._birthday;
    }

    public set Birthday(value: string) {
        this._birthday = value;
    }

    public get Sex(): string {
        return this._sex;
    }

    public set Sex(value: string) {
        this._sex = value;
    }

    public get SalutationId(): number {
        return this._salutationId;
    }

    public set SalutationId(value: number) {
        this._salutationId = value;
    }

    public get Avatar(): string {
        return this._avatar;
    }

    public set Avatar(value: string) {
        this._avatar = value;
    }

    public get AnsprechspartnerId(): number {
        return this._ansprechspartnerId;
    }

    public set AnsprechspartnerId(value: number) {
        this._ansprechspartnerId = value;
    }

    public get DisplayName(): string {
        return this._displayName;
    }

    public set DisplayName(value: string) {
        this._displayName = value;
    }

    public get Akquise(): ContactNoteEntity[] {
        return this._akquise;
    }

    public set Akquise(value: ContactNoteEntity[]) {
        this._akquise = value;
    }

    public get Ansprechspartner(): UserEntity {
        return this._ansprechsPartner;
    }

    public set Ansprechspartner(value: UserEntity) {
        this._ansprechsPartner = value;
    }

    static fromOdata(odataItem: any, entity: PersonEntity): PersonEntity {
        super.fromOdata(odataItem, entity);
        entity.DisplayName = odataItem["DisplayName"];
        entity.Initials = odataItem["Initials"];
        entity.CustomId = odataItem["CustomId"];
        entity.Title = odataItem["Title"];
        entity.FirstName = odataItem["FirstName"];
        entity.LastName = odataItem["LastName"];
        entity.Birthday = odataItem["Birthday"];
        entity.Sex = odataItem["Sex"];
        entity.SalutationId = odataItem["SalutationId"];
        entity.Avatar = odataItem["Avatar"];
        entity.AnsprechspartnerId = odataItem["AnsprechspartnerId"];
        entity.Akquise = odataItem["Akquise"] ? odataItem["Akquise"].map(a => ContactNoteEntity.fromOdata(a, new ContactNoteEntity())) : null;
        entity.Ansprechspartner = odataItem["Ansprechspartner"] ? UserEntity.fromOdata(odataItem["Ansprechspartner"], new UserEntity()) : null;
        return entity;
    }

    public toODataObject(): Object {
        const oDataObject = super.toODataObject();
        return oDataObject;
    }
}
