import BaseEntity from "./BaseEntity";

export default abstract class BaseAddressEntity extends BaseEntity {
    private _street: string;
    private _streetNumber: string;
    private _zipCode: string;
    private _city: string;
    private _country: string;
    private _countryCode: string;
    private _department: string;
    private _postOfficeBox: string;
    private _state: string;
    private _addressZusatz: string;
    private _addressTypeId: number;
    private _alias: string;
    private _isPrefered: boolean;
    private _translatedDisplayName: string;

    public get Street(): string {
        return this._street;
    }
    public set Street(value: string) {
        this._street = value;
    }

    public get StreetNumber(): string {
        return this._streetNumber;
    }
    public set StreetNumber(value: string) {
        this._streetNumber = value;
    }

    public get ZipCode(): string {
        return this._zipCode;
    }
    public set ZipCode(value: string) {
        this._zipCode = value;
    }

    public get City(): string {
        return this._city;
    }
    public set City(value: string) {
        this._city = value;
    }

    public get Country(): string {
        return this._country;
    }
    public set Country(value: string) {
        this._country = value;
    }

    public get CountryCode(): string {
        return this._countryCode;
    }
    public set CountryCode(value: string) {
        this._countryCode = value;
    }

    public get Department(): string {
        return this._department;
    }
    public set Department(value: string) {
        this._department = value;
    }

    public get PostOfficeBox(): string {
        return this._postOfficeBox;
    }
    public set PostOfficeBox(value: string) {
        this._postOfficeBox = value;
    }

    public get State(): string {
        return this._state;
    }
    public set State(value: string) {
        this._state = value;
    }

    public get AddressZusatz(): string {
        return this._addressZusatz;
    }
    public set AddressZusatz(value: string) {
        this._addressZusatz = value;
    }

    public get AddressTypeId(): number {
        return this._addressTypeId;
    }
    public set AddressTypeId(value: number) {
        this._addressTypeId = value;
    }

    public get Alias(): string {
        return this._alias;
    }

    public set Alias(value: string) {
        this._alias = value;
    }

    public get IsPrefered(): boolean {
        return this._isPrefered;
    }

    public set IsPrefered(value: boolean) {
        this._isPrefered = value;
    }

    public get TranslatedDisplayName(): string {
        return this._translatedDisplayName;
    }

    public set TranslatedDisplayName(value: string) {
        this._translatedDisplayName = value;
    }

    static fromOdata(odataItem: any, entity: BaseAddressEntity): BaseAddressEntity {
        super.fromOdata(odataItem, entity);
        entity.Street = odataItem["Street"] ?? "";
        entity.StreetNumber = odataItem["StreetNumber"] ?? "";
        entity.ZipCode = odataItem["ZipCode"] ?? "";
        entity.City = odataItem["City"] ?? "";
        entity.Country = odataItem["Country"] ?? "";
        entity.CountryCode = odataItem["CountryCode"] ?? "";
        entity.Department = odataItem["Department"] ?? "";
        entity.PostOfficeBox = odataItem["PostOfficeBox"] ?? "";
        entity.AddressZusatz = odataItem["AddressZusatz"] ?? "";
        entity.AddressTypeId = odataItem["AddressTypeId"];
        entity.Alias = odataItem["Alias"] ?? "";
        entity.IsPrefered = odataItem["IsPrefered"];
        entity.TranslatedDisplayName = odataItem["TranslatedDisplayName"] ?? "";
        return entity;
    }
}
