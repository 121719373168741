import Entity from "../Entities/Entity";
import BaseService from "./BaseService";

export default abstract class CachedBaseService<TEntity extends Entity> extends BaseService<TEntity> {
    private cachedItems: TEntity[] = null;

    public async getItems(filter?: string): Promise<TEntity[]> {
        if (!this.cachedItems) {
            this.cachedItems = await super.getItems(filter);
        }
        return this.cachedItems;
    }
}
