import Entity from "./Entity";
import CompanyEntity from "./CompaniesEntity";

export default class MeEntity extends Entity {
    private _firstName: string;
    private _lastName: string;
    private _email: string;
    private _loginId: number;
    private _userId: number;
    private _companyId: number;
    private _companies: CompanyEntity[];

    public get FirstName(): string {
        return this._firstName;
    }

    public set FirstName(value: string) {
        this._firstName = value;
    }

    public get LastName(): string {
        return this._lastName;
    }

    public set LastName(value: string) {
        this._lastName = value;
    }

    public get Email(): string {
        return this._email;
    }

    public set Email(value: string) {
        this._email = value;
    }

    public set LoginId(value: number) {
        this._loginId = value;
    }

    public get LoginId(): number {
        return this._loginId;
    }

    public set UserId(value: number) {
        this._userId = value;
    }

    public get UserId(): number {
        return this._userId;
    }

    public set CompanyId(value: number) {
        this._companyId = value;
    }

    public get CompanyId(): number {
        return this._companyId;
    }

    public get Companies(): CompanyEntity[] {
        return this._companies;
    }

    public set Companies(value: CompanyEntity[]) {
        this._companies = value;
    }

    public set PersonId(value: number) {
        this._companyId = value;
    }

    public get PersonId(): number {
        return this._companyId;
    }

    static fromOdata(odataItem: any, entity: MeEntity): MeEntity {
        super.fromOdata(odataItem, entity);
        entity.FirstName = odataItem["FirstName"];
        entity.LastName = odataItem["LastName"];
        entity.Email = odataItem["Email"];
        entity.UserId = odataItem["UserId"];
        entity.CompanyId = odataItem["CompanyId"];
        entity.Companies = odataItem["Companies"].map(c => CompanyEntity.fromOdata(c, new CompanyEntity()));
        entity.PersonId = odataItem["PersonId"];
        return entity;
    }
}
