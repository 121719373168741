import Network from "../network/network";
import Logger, { Loglevel } from "../util/Logger";
import Entity from "../Entities/Entity";

export default abstract class BaseService<TEntity extends Entity> {
    protected abstract getServiceName(): string;
    protected abstract getOdataRoute(): string;

    protected abstract mapDtoToEntity(dto: any): TEntity;

    protected readonly network: Network;
    protected readonly logger: Logger;

    constructor(network: Network, logger: Logger) {
        this.network = network;
        this.logger = logger;
    }

    public async getItems(filter?: string): Promise<TEntity[]> {
        try {
            const dtos = await this.network.requestApi(`${this.getOdataRoute()}${this.getRouteExtenstions(filter)}`);
            return dtos.value.map(dto => this.mapDtoToEntity(dto));
        } catch (e) {
            this.logger.log(Loglevel.Error, `${this.getServiceName()} failed to get items`, e);
        }
    }

    public getCount = async (filter: string): Promise<number> => {
        try {
            const dtos = await this.network.requestApi(`${this.getOdataRoute()}?$filter=${filter}&$top=0&$count=true`);
            return dtos["@odata.count"];
        } catch (e) {
            this.logger.log(Loglevel.Error, `${this.getServiceName()} failed to get items`, e);
        }
    };

    public getItem = async (id: number): Promise<TEntity> => {
        try {
            const dto = await this.network.requestApi(`${this.getOdataRoute()}(${id})`);
            return this.mapDtoToEntity(dto);
        } catch (e) {
            this.logger.log(Loglevel.Error, `${this.getServiceName()} failed to get single item`, e);
        }
    };

    private getRouteExtenstions = (filter: string): string => {
        let extension = "?";
        if (filter) {
            extension += `$filter=${filter}`;
        }
        if (this.getExpands().length) {
            extension += `${extension.length > 1 ? "&" : ""}`;
            extension += `$expand=${this.getExpands().join(",")}`;
        }
        if (this.getSelects().length) {
            extension += `${extension.length > 1 ? "&" : ""}`;
            extension += `$select=${this.getSelects().join(",")}`;
        }
        return extension;
    };

    protected getExpands = (): string[] => {
        return [];
    };

    protected getSelects = (): string[] => {
        return [];
    };
}
