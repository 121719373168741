import BaseAddressEntity from "../Entities/BaseAddressEntity";
import BaseContactEntity from "../Entities/BaseContactEntity";
import ContactMediaEntity from "../Entities/ContactMediaEntity";
import InstitutionEntity from "../Entities/InstitutionEntity";
import Network from "../network/network";
import ContactBaseModel from "./ContactBaseModel";
import ContactModel from "./ContactModel";

export default class InstitutionModel extends ContactBaseModel {
    private readonly _institution: InstitutionEntity;
    private _avatar: string;

    constructor(institution: InstitutionEntity, contactMedia: BaseContactEntity[], address: BaseAddressEntity[], contactMediaTypes: ContactMediaEntity[]) {
        super(contactMedia, address, contactMediaTypes);
        this._institution = institution;
    }

    get Id(): number {
        return this._institution.Id;
    }

    get CustomId(): string {
        return this._institution.CustomId;
    }

    get OrphyLink(): string {
        return `${Network.BaseURL}InstitutionDetail/Details/${this._institution.Id}`;
    }

    get DisplayName(): string {
        return `${this._institution.Name} ${this.LegalForm ?? ""}`;
    }

    get Avatar(): string {
        return this._avatar;
    }

    set Avatar(val: string) {
        this._avatar = val;
    }

    get HasAvatar(): boolean {
        return !!this.Avatar;
    }

    private get LegalForm(): string {
        return this._institution.LegalForm?.TranslatedDisplayName;
    }

    get HasContactPerson(): boolean {
        return !!this._institution.ContactPerson;
    }

    get ContactPerson(): ContactModel {
        if (this.HasContactPerson) {
            return new ContactModel(this._institution.ContactPerson.DisplayName, `${Network.BaseURL}PersonDetail/Details/${this._institution.ContactPerson.Id}`);
        }
        return null;
    }
}
