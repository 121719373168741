import BaseEntity from "./BaseEntity";
import PersonEntity from "./PersonEntity";
import InstitutionEntity from "./InstitutionEntity";
import PersonToInstitutionRelationEntity from "./PersonToInstitutionRelationEntity";

export default class TodoEntity extends BaseEntity {
    private _titel: string;
    private _beschreibung: string;
    private _priority: Priority;
    private _abgeschlossen: boolean;
    private _start: string;
    private _end: string;
    private _personsInCharge: string[];
    private _persons: PersonEntity[];
    private _institutions: InstitutionEntity[];
    private _personToInstitutionRelations: PersonToInstitutionRelationEntity[];
    private _relationId: number;
    private _leadId: number;
    private _todoOwners: number[];
    private _reminderSeconds: number;
    private _reminderEnabled: boolean;

    public get Titel(): string {
        return this._titel;
    }

    public set Titel(value: string) {
        this._titel = value;
    }

    public get Beschreibung(): string {
        return this._beschreibung;
    }

    public set Beschreibung(value: string) {
        this._beschreibung = value;
    }

    public get Priority(): Priority {
        return this._priority;
    }

    public set Priority(value: Priority) {
        this._priority = value;
    }

    public get Abgeschlossen(): boolean {
        return this._abgeschlossen;
    }

    public set Abgeschlossen(value: boolean) {
        this._abgeschlossen = value;
    }

    public get RelationId() {
        return this._relationId;
    }

    public set RelationId(value: number) {
        this._relationId = value;
    }

    public get Start(): string {
        return this._start;
    }

    public set Start(value: string) {
        this._start = value;
    }

    public get End(): string {
        return this._end;
    }

    public set End(value: string) {
        this._end = value;
    }

    public get PersonsInCharge(): string[] {
        return this._personsInCharge;
    }

    public set PersonsInCharge(value: string[]) {
        this._personsInCharge = value;
    }

    public get Persons(): PersonEntity[] {
        return this._persons;
    }

    public set Persons(value: PersonEntity[]) {
        this._persons = value;
    }

    public get Institutions(): InstitutionEntity[] {
        return this._institutions;
    }

    public set Institutions(value: InstitutionEntity[]) {
        this._institutions = value;
    }
    public get PersonToInstitutionRelations(): PersonToInstitutionRelationEntity[] {
        return this._personToInstitutionRelations;
    }

    public set PersonToInstitutionRelations(value: PersonToInstitutionRelationEntity[]) {
        this._personToInstitutionRelations = value;
    }

    get LeadId() {
        return this._leadId;
    }

    set LeadId(val: number) {
        this._leadId = val;
    }

    get TodoOwners() {
        return this._todoOwners;
    }

    set TodoOwners(val: number[]) {
        this._todoOwners = val;
    }

    get ReminderSeconds() {
        return this._reminderSeconds;
    }

    set ReminderSeconds(val: number) {
        this._reminderSeconds = val;
    }

    get ReminderEnabled() {
        return this._reminderEnabled;
    }

    set ReminderEnabled(val: boolean) {
        this._reminderEnabled = val;
    }

    public static fromOdata(odataItem: any, entity: TodoEntity): TodoEntity {
        super.fromOdata(odataItem, entity);
        entity.Titel = odataItem["Titel"];
        entity.Beschreibung = odataItem["Beschreibung"];
        entity.Priority = Priority[odataItem["EPriority"]];
        entity.Abgeschlossen = odataItem["Abgeschlossen"];
        entity.Start = odataItem["Start"];
        entity.End = odataItem["End"];
        entity.PersonsInCharge = odataItem["PersonsInCharge"];
        entity.RelationId = odataItem["RelationId"];
        entity.PersonToInstitutionRelations = odataItem["PersonToInstitutionRelations"]?.map(e =>
            PersonToInstitutionRelationEntity.fromOdata(e, new PersonToInstitutionRelationEntity())
        );
        entity.Persons = odataItem["Persons"]?.map(p => PersonEntity.fromOdata(p, new PersonEntity()));
        entity.Institutions = odataItem["Institutions"]?.map(i => InstitutionEntity.fromOdata(i, new InstitutionEntity()));
        entity.ReminderSeconds = odataItem["ReminderSeconds"];
        entity.ReminderEnabled = odataItem["ReminderEnabled"];
        return entity;
    }

    static toOdataObject(todo: TodoEntity): any {
        const ret = {
            Titel: todo.Titel,
            Beschreibung: todo.Beschreibung,
            EPriority: todo.Priority,
            Abgeschlossen: false,
            ReminderEnabled: todo.ReminderEnabled,
            ReminderSeconds: todo.ReminderEnabled ? todo.ReminderSeconds : 0,
        };
        if (todo.End) {
            ret["End"] = todo.End;
        }
        if (todo.Start) {
            ret["Start"] = todo.Start;
        }
        if (todo.TodoOwners.length) {
            ret["TodoOwners"] = todo.TodoOwners.map(uid => ({ UserId: uid }));
        }
        if (todo.LeadId) {
            ret["LeadId"] = todo.LeadId;
        }
        return ret;
    }
}

enum Priority {
    "Prio0" = "Keine Priorität",
    "Prio1" = "Sehr niedrige Priorität",
    "Prio2" = "Niedrige Priorität",
    "Prio3" = "Mittlere Priorität",
    "Prio4" = "Hohe Priorität",
    "Prio5" = "Dringend zu erledigen",
}
