import InstitutionEntity from "../Entities/InstitutionEntity";
import InstitutionModel from "../models/InstitutionModel";
import Network from "../network/network";
import { EntityTypeId } from "../util/EntityTypeId";
import Logger, { Loglevel } from "../util/Logger";
import Util from "../util/Util";
import AvatarService from "./AvatarService";
import BaseService from "./BaseService";
import ContactMediaService from "./ContactMediaService";
import InstitutionAddressService from "./InstitutionAddressService";
import InstitutionContactService from "./InstitutionContactService";

export default class InstitutionService extends BaseService<InstitutionEntity> {
    private SERVICE_NAME = "InstitutionService";
    private ODATA_ROUTE = "institution";

    private readonly contactService: InstitutionContactService;
    private readonly institutionAddressService: InstitutionAddressService;
    private readonly avatarService: AvatarService;
    private readonly contactMediaTypeService: ContactMediaService;

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): InstitutionEntity {
        return InstitutionEntity.fromOdata(dto, new InstitutionEntity());
    }

    constructor(
        network: Network,
        contactService: InstitutionContactService,
        institutionAddressService: InstitutionAddressService,
        avatarService: AvatarService,
        contactMediaTypeService: ContactMediaService,
        logger: Logger
    ) {
        super(network, logger);
        this.contactService = contactService;
        this.institutionAddressService = institutionAddressService;
        this.avatarService = avatarService;
        this.contactMediaTypeService = contactMediaTypeService;
    }

    public getInstitutionModelById = async (id: number): Promise<InstitutionModel> => {
        const institutionDto: any = Util.firstOrDefault(await this.getItems(`Id eq ${id}`));
        if (institutionDto) {
            const institution = InstitutionEntity.fromOdata(institutionDto, new InstitutionEntity());
            const contactMedia = await this.contactService.getContactMedia(institution.Id);
            const institutionAddresses = await this.institutionAddressService.getAddressesByInstitutionID(institution.Id);
            const contactMediaTypes = await this.contactMediaTypeService.getItems();
            const institutionModel = new InstitutionModel(institution, contactMedia, institutionAddresses, contactMediaTypes);
            institutionModel.Avatar = await this.avatarService.getAvatar(institutionModel.Id, EntityTypeId.InstitutionEntity);
            return institutionModel;
        }
        return null;
    };

    public findInstitutionByEmail = async (email: string): Promise<number> => {
        try {
            const items = await this.network.requestApi(`${this.ODATA_ROUTE}?$filter=Contacts/any(contact:contact/NameInMedia eq '${email}')&$select=Id`);
            const item = Util.firstOrDefault(items.value);
            if (item) {
                return item["Id"];
            }
            return null;
        } catch (e) {
            this.logger.log(Loglevel.Error, `Could not fetch id for institution`, e);
        }
    };

    protected getExpands = (): string[] => {
        return ["LegalForm", "ContactPerson"];
    };
}
