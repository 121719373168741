import CurrencyEntity from "../Entities/CurrencyEntity";
import CachedBaseService from "./CachedBaseService";

export default class CurrencyService extends CachedBaseService<CurrencyEntity> {
    private SERVICE_NAME = "CurrencyService";
    private ODATA_ROUTE = "currency";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): CurrencyEntity {
        return CurrencyEntity.fromOdata(dto, new CurrencyEntity());
    }
}
