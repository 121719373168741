import BaseEntity from "./BaseEntity";
import ContactNoteEntity from "./ContactNoteEntity";
import LegalFormEntity from "./LegalFormEntity";
import PersonEntity from "./PersonEntity";

export default class InstitutionEntity extends BaseEntity {
    private _name: string;
    private _avatar: string;
    private _customId: string;
    private _legalFormId: number;
    private _anzahlMitarbeiter: AnzahlMitarbeiter;
    private _mwstNummer: string;
    private _uid: string;
    private _tags: [];
    private _personInChargeId: number;
    private _contactPersonId: number;
    private _akquise: ContactNoteEntity[];
    private _legalForm: LegalFormEntity;
    private _contactPerson: PersonEntity;

    public get Name(): string {
        return this._name;
    }

    public set Name(value: string) {
        this._name = value;
    }

    public get Avatar(): string {
        return this._avatar;
    }

    public set Avatar(value: string) {
        this._avatar = value;
    }

    public get CustomId(): string {
        return this._customId;
    }

    public set CustomId(value: string) {
        this._customId = value;
    }

    public get LegalFormId(): number {
        return this._legalFormId;
    }

    public set LegalFormId(value: number) {
        this._legalFormId = value;
    }

    public get AnzahlMitarbeiter(): AnzahlMitarbeiter {
        return this._anzahlMitarbeiter;
    }

    public set AnzahlMitarbeiter(value: AnzahlMitarbeiter) {
        this._anzahlMitarbeiter = value;
    }

    public get MwstNummer(): string {
        return this._mwstNummer;
    }

    public set MwstNummer(value: string) {
        this._mwstNummer = value;
    }

    public get UID(): string {
        return this._uid;
    }

    public set UID(value: string) {
        this._uid = value;
    }

    public get Tags(): [] {
        return this._tags;
    }

    public set Tags(value: []) {
        this._tags = value;
    }

    public get PersonInChargeId(): number {
        return this._personInChargeId;
    }

    public set PersonInChargeId(value: number) {
        this._personInChargeId = value;
    }

    public get ContactPersonId(): number {
        return this._contactPersonId;
    }

    public set ContactPersonId(value: number) {
        this._contactPersonId = value;
    }

    public get Akquise(): ContactNoteEntity[] {
        return this._akquise;
    }

    public set Akquise(value: ContactNoteEntity[]) {
        this._akquise = value;
    }

    public get LegalForm(): LegalFormEntity {
        return this._legalForm;
    }

    public set LegalForm(value: LegalFormEntity) {
        this._legalForm = value;
    }

    public get ContactPerson(): PersonEntity {
        return this._contactPerson;
    }

    public set ContactPerson(value: PersonEntity) {
        this._contactPerson = value;
    }

    static fromOdata(odataItem: any, entity: InstitutionEntity): InstitutionEntity {
        super.fromOdata(odataItem, entity);
        entity.Name = odataItem["Name"];
        entity.Avatar = odataItem["Avatar"];
        entity.CustomId = odataItem["CustomId"];
        entity.LegalFormId = odataItem["LegalFormId"];
        entity.AnzahlMitarbeiter = AnzahlMitarbeiter[odataItem["AnzahlMitarbeiter"]];
        entity.MwstNummer = odataItem["MwstNummer"];
        entity.UID = odataItem["UID"];
        entity.Tags = odataItem["Tags"];
        entity.PersonInChargeId = odataItem["PersonInChargeId"];
        entity.ContactPersonId = odataItem["ContactPersonId"];
        entity.Akquise = odataItem["Akquise"] ? odataItem["Akquise"].map(a => ContactNoteEntity.fromOdata(a, new ContactNoteEntity())) : null;
        entity.LegalForm = odataItem["LegalForm"] ? LegalFormEntity.fromOdata(odataItem["LegalForm"], new LegalFormEntity()) : null;
        entity.ContactPerson = odataItem["ContactPerson"] ? PersonEntity.fromOdata(odataItem["ContactPerson"], new PersonEntity()) : null;
        return entity;
    }
}

export enum AnzahlMitarbeiter {
    "Unbekannt" = "Unbekannt",
    "Von1Bis10" = "Von 1 bis 10",
    "Von11Bis20" = "Von 11 bis 20",
    "Von21Bis50" = "Von 21 bis 50",
    "Von51Bis100" = "Von 21 bis 50",
    "MehrAls100" = "Mehr als 100",
}
