import BaseEntity from "./BaseEntity";
import PersonEntity from "./PersonEntity";
import InstitutionEntity from "./InstitutionEntity";

export default class LeadEntity extends BaseEntity {
    private _titel: string;
    private _price: number;
    private _dueDate: string;
    private _overallState: EOverallStatus;
    private _state: EStatus;
    private _personId: number;
    private _institutionId: number;
    private _currencyId: number;
    private _contactPersonId: number;
    private _person: PersonEntity;
    private _institution: InstitutionEntity;
    private _contactPerson: PersonEntity;

    public get Titel(): string {
        return this._titel;
    }

    public set Titel(value: string) {
        this._titel = value;
    }

    public get Price(): number {
        return this._price;
    }

    public set Price(value: number) {
        this._price = value;
    }

    public get DueDate(): string {
        return this._dueDate;
    }

    public set DueDate(value: string) {
        this._dueDate = value;
    }

    public get OverallState(): EOverallStatus {
        return this._overallState;
    }

    public set OverallState(value: EOverallStatus) {
        this._overallState = value;
    }

    public get State(): EStatus {
        return this._state;
    }

    public set State(value: EStatus) {
        this._state = value;
    }

    public get PersonId(): number {
        return this._personId;
    }

    public set PersonId(value: number) {
        this._personId = value;
    }

    public get InstitutionId(): number {
        return this._institutionId;
    }

    public set InstitutionId(value: number) {
        this._institutionId = value;
    }

    public get CurrencyId(): number {
        return this._currencyId;
    }
    public set CurrencyId(value: number) {
        this._currencyId = value;
    }

    public get ContactPersonId(): number {
        return this._contactPersonId;
    }

    public set ContactPersonId(value: number) {
        this._contactPersonId = value;
    }

    public get Person(): PersonEntity {
        return this._person;
    }

    public set Person(value: PersonEntity) {
        this._person = value;
    }

    public get Institution(): InstitutionEntity {
        return this._institution;
    }

    public set Institution(value: InstitutionEntity) {
        this._institution = value;
    }

    public get ContactPerson(): PersonEntity {
        return this._contactPerson;
    }

    public set ContactPerson(value: PersonEntity) {
        this._contactPerson = value;
    }

    public static fromOdata(odataItem: any, entity: LeadEntity): LeadEntity {
        super.fromOdata(odataItem, entity);
        entity.Titel = odataItem["Titel"];
        entity.Price = odataItem["Price"];
        entity.DueDate = odataItem["DueDate"];
        entity.OverallState = EOverallStatus[odataItem["EOverallState"]];
        entity.State = EStatus[odataItem["EState"]];
        entity.PersonId = odataItem["PersonId"];
        entity.InstitutionId = odataItem["InstitutionId"];
        entity.CurrencyId = odataItem["CurrencyId"];
        entity.ContactPersonId = odataItem["ContactPersonId"];
        entity.ContactPerson = odataItem["ContactPerson"] ? PersonEntity.fromOdata(odataItem["ContactPerson"], new PersonEntity()) : null;
        entity.Person = odataItem["Person"] ? PersonEntity.fromOdata(odataItem["Person"], new PersonEntity()) : null;
        entity.Institution = odataItem["Institution"] ? InstitutionEntity.fromOdata(odataItem["Institution"], new InstitutionEntity()) : null;
        return entity;
    }

    static toOdataObject(lead: LeadEntity): any {
        const ret = {
            Titel: lead.Titel,
            CurrencyId: lead.CurrencyId,
            ContactPersonId: lead.ContactPersonId,
        };
        if (lead.OverallState) {
            ret["OverallState"] = lead.OverallState;
        }
        if (lead.State) {
            ret["State"] = lead.State;
        }
        if (lead.PersonId) {
            ret["PersonId"] = lead.PersonId;
        }
        if (lead.InstitutionId) {
            ret["InstitutionId"] = lead.InstitutionId;
        }
        if (lead.Price) {
            ret["Price"] = lead.Price;
        }
        if (lead.DueDate) {
            ret["DueDate"] = lead.DueDate;
        }
        return ret;
    }
}

enum EStatus {
    "State1" = "Qualifizierter Lead (Interesse vorhanden)",
    "State2" = "Gespräch durchgeführt (Produktpräsentation)",
    "State3" = "Umfang und Preise besprochen (weiteres Interesse vorhanden)",
    "State4" = "Verhandlungen gestartet (interne Abklärungen)",
    "State5" = "Definitives Angebot abgegeben (mündliche Zusage)",
}

enum EOverallStatus {
    "None" = "Offen",
    "Won" = "Gewonnen",
    "Loss" = "Verloren",
    "Neutral" = "Neutral",
}
