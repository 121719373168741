import Network from "../network/network";
import MeEntity from "../Entities/MeEntity";

export default class MeService {
    private SERVICE_NAME = "MeService";
    private ODATA_ROUTE = "Me";

    private readonly network: Network;

    constructor(network: Network) {
        this.network = network;
    }

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): MeEntity {
        return MeEntity.fromOdata(dto, new MeEntity());
    }

    public getMe = async () => {
        const data = await (await this.network.requestPublicApi(this.getOdataRoute())).json();
        return this.mapDtoToEntity(data);
    };

    public switchCompany = async (companyId: number): Promise<void> => {
        await this.network.sendPublicApi(`${this.ODATA_ROUTE}/switchcompany`, companyId);
    };
}
