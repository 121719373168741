import IAttachmentInterface from "../interfaces/IAttachmentInterface";
import Network from "../network/network";
import { EntityTypeId } from "../util/EntityTypeId";
import Logger, { Loglevel } from "../util/Logger";

export default class AttachementService {
    private SERVICE_NAME = "AttachementService";
    private API_ROUTE = "Attachment/Upload";

    private readonly network: Network;
    private readonly logger: Logger;

    constructor(network: Network, logger: Logger) {
        this.network = network;
        this.logger = logger;
    }

    public uploadAttachments = async (entityId: string, entityTypeId: EntityTypeId, files: FormData): Promise<void> => {
        try {
            const headers = new Headers();
            headers.append("Authorization", this.network.getAuthorizationHeader(await this.network.authenticate()));
            await fetch(`${Network.APIBaseURL}${this.API_ROUTE}?entityId=${entityId}&entityTypeId=${entityTypeId}`, {
                method: "POST",
                headers: headers,
                body: files,
            });
        } catch (e) {
            this.logger.log(Loglevel.Error, `${this.SERVICE_NAME}: Unable to upload attachement`, e);
        }
    };

    public getAttachments = async (entityUuid: string, entityTypeId: EntityTypeId): Promise<IAttachmentInterface[]> => {
        const attachments = await (await this.network.requestPublicApi(`Attachment/GetAttachments?entityId=${entityUuid}&entityTypeId=${entityTypeId}`)).json();
        return attachments as IAttachmentInterface[];
    };

    public deleteAttachments = async (entityUuid: string, entityTypeId: EntityTypeId, attachments: IAttachmentInterface[]): Promise<void> => {
        await Promise.all(
            attachments.map(async a => {
                await this.network.sendPublicApi(`Attachment/Delete?entityId=${entityUuid}&entityTypeId=${entityTypeId}`, { Id: a.Id });
            })
        );
    };
}
