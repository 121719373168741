import BaseEntity from "./BaseEntity";
import LeadEntity from "./LeadEntity";

export default class ContactNoteEntity extends BaseEntity {
    private _note: string;
    private _subject: string;
    private _mood: string;
    private _date: string;
    private _contactTypeId: number;
    private _createdAt: string;
    private _lead: LeadEntity;
    private _leadId: number;

    public get Note(): string {
        return this._note;
    }

    public set Note(value: string) {
        this._note = value;
    }

    public get Subject(): string {
        return this._subject;
    }

    public set Subject(value: string) {
        this._subject = value;
    }

    public get Mood(): string {
        return this._mood;
    }

    public set Mood(value: string) {
        this._mood = value;
    }

    public get Date(): string {
        return this._date;
    }

    public set Date(value: string) {
        this._date = value;
    }

    public get ContactTypeId(): number {
        return this._contactTypeId;
    }

    public set ContactTypeId(value: number) {
        this._contactTypeId = value;
    }

    public get CreatedAt(): string {
        return this._createdAt;
    }

    public set CreatedAt(value: string) {
        this._createdAt = value;
    }

    public get Lead(): LeadEntity {
        return this._lead;
    }

    public set Lead(value: LeadEntity) {
        this._lead = value;
    }

    public get LeadId(): number {
        return this._leadId;
    }

    public set LeadId(value: number) {
        this._leadId = value;
    }

    static fromOdata(odataItem: any, entity: ContactNoteEntity): ContactNoteEntity {
        super.fromOdata(odataItem, entity);
        entity.Note = odataItem["Note"];
        entity.Subject = odataItem["Subject"];
        entity.Mood = odataItem["Mood"];
        entity.Date = odataItem["Date"];
        entity.ContactTypeId = odataItem["ContactTypeId"];
        entity.CreatedAt = odataItem["CreatedAt"];
        entity.Lead = odataItem["Lead"] ? LeadEntity.fromOdata(odataItem["Lead"], new LeadEntity()) : null;
        return entity;
    }

    static toOdataObject(contactNote: ContactNoteEntity): any {
        return {
            Note: contactNote.Note,
            Subject: contactNote.Subject,
            Mood: contactNote.Mood,
            Date: contactNote.Date,
            ContactTypeId: contactNote.ContactTypeId,
            LeadId: contactNote.LeadId,
        };
    }
}
