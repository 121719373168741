import BaseAddressEntity from "../Entities/BaseAddressEntity";
import BaseContactEntity from "../Entities/BaseContactEntity";
import ContactMediaEntity from "../Entities/ContactMediaEntity";
import { ContactMediaTypeV3 } from "../Entities/ContactMediaType";
import Util from "../util/Util";

export default abstract class ContactBaseModel {
    private readonly _contactMedia: BaseContactEntity[];
    private readonly _address: BaseAddressEntity[];
    private readonly _contactMediaTypes: ContactMediaEntity[];

    constructor(contactMedia: BaseContactEntity[], address: BaseAddressEntity[], contactMediaTypes: ContactMediaEntity[]) {
        this._contactMedia = contactMedia ? contactMedia : [];
        this._address = address ? address : [];
        this._contactMediaTypes = contactMediaTypes;
    }

    abstract get OrphyLink();
    abstract get DisplayName();
    abstract get Avatar();
    abstract get HasAvatar();
    abstract get HasContactPerson();
    abstract get ContactPerson();
    abstract get CustomId(): string;

    get PreferredEmailAddress(): string {
        const email = this._contactMedia.find(e => e.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.EMail.toString()).Id && e.IsPrefered);
        if (email) {
            return email.NameInMedia;
        }
        return Util.firstOrDefault(this._contactMedia.filter(e => e.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.EMail.toString()).Id))?.NameInMedia;
    }

    get MailTo(): string {
        return `mailto:${this.PreferredEmailAddress}`;
    }

    get HasEmailAddress(): boolean {
        return !!this.PreferredEmailAddress;
    }

    get CallTo(): string {
        return `tel:${this.PreferredPhoneNumber}`;
    }

    get MapTo(): string {
        if (this.HasAddress) {
            return `https://maps.google.ch?q=${this.FullAddress}`;
        } else {
            return "#";
        }
    }

    get FullAddress(): string {
        if (this.HasAddress) {
            return `${this.PreferredAddress.Street + " " ?? ""}${this.PreferredAddress.StreetNumber + " " ?? ""}${this.PreferredAddress.ZipCode + " " ?? ""}${this.PreferredAddress.City ?? ""}`;
        }
    }

    get PreferredAddress(): BaseAddressEntity {
        const pAddress = this._address.find(e => e.IsPrefered);
        if (pAddress) {
            return pAddress;
        }
        return Util.firstOrDefault(this._address);
    }

    get PreferredPhoneNumber(): string {
        const phoneNumber = this._contactMedia.find(
            c =>
                (c.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Phone.toString()).Id ||
                    c.Id === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Mobile.toString()).Id) &&
                c.IsPrefered
        );
        if (phoneNumber) {
            return phoneNumber.NameInMedia;
        }
        return Util.firstOrDefault(
            this._contactMedia.filter(
                c =>
                    c.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Phone.toString()).Id ||
                    c.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Mobile.toString()).Id
            )
        )?.NameInMedia;
    }

    get Website(): string {
        const webSite = this._contactMedia.find(c => c.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Website.toString()).Id && c.IsPrefered);
        if (webSite) {
            if (webSite.NameInMedia.startsWith("http")) {
                return webSite.NameInMedia;
            } else {
                return "http://" + webSite.NameInMedia;
            }
        }
        const site = Util.firstOrDefault(this._contactMedia.filter(c => c.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Website.toString()).Id))?.NameInMedia;
        if (site) {
            if (site.startsWith("http")) {
                return site;
            } else {
                return "http://" + site;
            }
        }
        return null;
    }

    get HasAddress(): boolean {
        const pa = this.PreferredAddress;
        return !!pa && (!!pa.Street || !!pa.ZipCode || !!pa.City);
    }

    get HasPhoneNumber(): boolean {
        return !!this._contactMedia.filter(
            c =>
                c.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Phone.toString()).Id ||
                c.Id === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Mobile.toString()).Id
        ).length;
    }

    get HasWebsite(): boolean {
        return !!this._contactMedia.filter(c => c.MediaId === this._contactMediaTypes.find(c => c.MediaType === ContactMediaTypeV3.Website.toString()).Id).length;
    }
}
