import AppModel from "../../viewModels/appModel";
import OrphyNetwork from "../../network/orphyNetwork";
import BaseModel from "../../viewModels/BaseModel";

$(async () => {
    kendo.culture("de-CH");
    const taskModel = new AppModel();
    await taskModel.init();
    kendo.bind($("#app-body"), taskModel);

    BaseModel.localize();

    OrphyNetwork.handleRedirect(
        () => {
            let url = window.location.href;
            window.history.pushState("", "", url.substring(0, url.lastIndexOf("#")));
            taskModel.set("initialized", true);
            taskModel.load();
        },
        () => {
            taskModel.login();
        }
    );
});
