import { EntityTypeId } from "../util/EntityTypeId";
import Util from "../util/Util";

export default class SearchEntity {
    private _text: string;
    private _groupName: string;
    private _entityTypeId: EntityTypeId;
    private _entityId: number;
    private _avatar: string;
    private _relationName: string;
    private _renderImage: string;

    public get Text(): string {
        return this._text;
    }

    public set Text(value: string) {
        this._text = value;
    }

    public get RelationName() {
        return this._relationName;
    }

    public set RelationName(val: string) {
        this._relationName = val;
    }

    public get GroupName(): string {
        return this._groupName;
    }

    public set GroupName(value: string) {
        this._groupName = value;
    }

    public get EntityTypeId(): EntityTypeId {
        return this._entityTypeId;
    }

    public set EntityTypeId(value: EntityTypeId) {
        this._entityTypeId = value;
    }

    public get EntityId(): number {
        return this._entityId;
    }

    public set EntityId(value: number) {
        this._entityId = value;
    }

    public set Picture(value: string) {
        this._avatar = value;
    }

    public get Picture() {
        return this._avatar;
    }

    public set RenderImage(value: string) {
        this._renderImage = value;
    }

    public get RenderImage() {
        return this._renderImage;
    }

    public static fromOdata(odataItem: any, entity: SearchEntity): SearchEntity {
        entity.Text = odataItem["Text"];
        entity.GroupName = odataItem["GroupName"];
        entity.EntityTypeId = odataItem["EntityTypeId"];
        entity.EntityId = odataItem["EntityId"];
        entity.Picture = odataItem["Picture"].replace("&width=25&height=25&", "&width=50&height=50&");
        entity.RenderImage = Util.getEmptyAvatar();
        return entity;
    }
}
