import BaseService from "./BaseService";
import InstitutionContactEntity from "../Entities/InstitutionContactEntity";

export default class InstitutionContactService extends BaseService<InstitutionContactEntity> {
    private SERVICE_NAME = "InstitutionContactService";
    private ODATA_ROUTE = "institutioncontact";

    protected getServiceName(): string {
        return this.SERVICE_NAME;
    }

    protected getOdataRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected mapDtoToEntity(dto: any): InstitutionContactEntity {
        return InstitutionContactEntity.fromOdata(dto, new InstitutionContactEntity());
    }

    public getContactMedia = async (institutionId: number): Promise<InstitutionContactEntity[]> => {
        return await this.getItems(`InstitutionId eq ${institutionId}`);
    };
}
