export enum ContactMediaTypeV3 {
    Custom = "Custom",
    Phone = "Phone",
    Mobile = "Mobile",
    Fax = "Fax",
    EMail = "EMail",
    Website = "Website",
    Skype = "Skype",
    Twitter = "Twitter",
}
