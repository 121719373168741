import ContactBaseModel from "./ContactBaseModel";

export default class RelationContactModel extends ContactBaseModel {
    get CustomId(): string {
        return null;
    }

    private _relationName: string;

    get OrphyLink(): any {
        return null;
    }

    get DisplayName(): any {
        return null;
    }

    get Avatar(): any {
        return null;
    }

    get HasAvatar(): any {
        return false;
    }

    get HasContactPerson(): any {
        return false;
    }

    get ContactPerson(): any {
        return null;
    }

    get RelationName(): string {
        return this._relationName;
    }

    set RelationName(val: string) {
        this._relationName = val;
    }
}
