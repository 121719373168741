export default class ContactModel {
    private readonly _displayName: string;
    private readonly _orphyLink: string;

    constructor(displayName: string, orphyLink: string = null) {
        this._displayName = displayName;
        this._orphyLink = orphyLink;
    }

    get DisplayName(): string {
        return this._displayName;
    }

    get OrphyLink(): string {
        return this._orphyLink;
    }

    get HasLink(): boolean {
        return !!this.OrphyLink;
    }
}
