import i18next, { i18n, TFunction } from "i18next";
import i18nextHttpBackend from "i18next-http-backend";
import locI18next from "loc-i18next";

export default class Translation {
    private readonly availableLanguages = ["de", "en", "fr", "it"];
    private readonly fallbackLang = "de";
    private i18next: i18n;
    public translateKey: TFunction<["translation", ...string[]], undefined>;
    public t: TFunction<["translation", ...string[]], undefined>;
    public localize: (element: string) => void;

    public async init(): Promise<void> {
        let lang = navigator.language.substring(0, 2);
        if (!this.availableLanguages.find(x => x === lang)) {
            lang = this.fallbackLang;
        }
        if (this.i18next) {
            return Promise.resolve();
        }
        await new Promise<void>(res => {
            i18next.use(i18nextHttpBackend).init(
                {
                    fallbackLng: this.fallbackLang,
                    lng: lang,
                    backend: {
                        loadPath: "/resources/{{lng}}/{{ns}}.json",
                    },
                },
                error => {
                    if (error) {
                        console.error(error);
                    }
                    this.localize = locI18next.init(i18next, {
                        optionsAttr: "data-i18n-options",
                        useOptionsAttr: true,
                    });
                    this.i18next = i18next;
                    this.t = i18next.t;
                    (window as any).bla = this;
                    res();
                }
            );
        });
    }
}
