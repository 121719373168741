import TodoEntity from "../Entities/TodoEntity";
import Network from "../network/network";
import DateTime from "../util/DateTime";
import BaseModel from "../viewModels/BaseModel";
import ContactModel from "./ContactModel";

export default class TodoModel extends kendo.data.ObservableObject {
    private readonly _todo: TodoEntity;
    private _detailVisible: boolean;
    private _avatar: string;
    private _isBoundToMail: boolean;

    constructor(todo: TodoEntity) {
        super();
        this._todo = todo;
        super.init(this);
    }

    get Uuid() {
        return this._todo.Uuid;
    }

    get Id(): number {
        return this._todo.Id;
    }

    get Avatar(): string {
        return this._avatar;
    }

    set Avatar(val: string) {
        this._avatar = val;
    }

    get Title(): string {
        return this._todo.Titel;
    }

    get Priority(): string {
        return this._todo.Priority;
    }

    get PersonsInCharge(): string {
        return this._todo.PersonsInCharge.join(", ");
    }

    get HasBeschreibung(): boolean {
        return !!this._todo.Beschreibung;
    }

    get Beschreibung(): string {
        return this._todo.Beschreibung;
    }

    get OrphyTodoLink(): string {
        return `${Network.BaseURL}TodoDetail/Details/${this._todo.Id}`;
    }

    get HasStart(): boolean {
        return !!this._todo.Start;
    }

    get Start(): string {
        if (this._todo.Start) {
            return DateTime.parseNumberDateTime(new Date(this._todo.Start));
        }
    }

    get HasEnd(): boolean {
        return !!this._todo.End;
    }

    get End(): string {
        if (this._todo.End) {
            return DateTime.parseNumberDateTime(new Date(this._todo.End));
        }
    }

    get HasPersons(): boolean {
        return !!this._todo.Persons.length;
    }

    get HasInstitutions(): boolean {
        return !!this._todo.Institutions.length;
    }

    private get Persons(): ContactModel[] {
        return this._todo.Persons?.map(p => new ContactModel(p.DisplayName, `${Network.BaseURL}PersonDetail/Details/${p.Id}`));
    }

    private get Institutions(): ContactModel[] {
        return this._todo.Institutions?.map(i => new ContactModel(i.Name, `${Network.BaseURL}InstitutionDetail/Details/${i.Id}`));
    }

    get Contacts(): ContactModel[] {
        return this.Persons.concat(this.Institutions).concat(this.PersonToInstitutionRelations);
    }

    get HasContacts(): boolean {
        return this.HasPersons || this.HasInstitutions || this.HasPersonToInstitutionRelations;
    }

    get HasPersonToInstitutionRelations(): boolean {
        return !!this.PersonToInstitutionRelations.length;
    }

    get PersonToInstitutionRelations(): ContactModel[] {
        return this._todo.PersonToInstitutionRelations.map(
            ptir => new ContactModel(`${ptir.PersonDisplayName} ${ptir.Relation.TranslatedDisplayName} bei ${ptir.InstitutionDisplayName}`)
        );
    }

    get DetailVisible(): boolean {
        return this._detailVisible;
    }

    set DetailVisible(val: boolean) {
        this._detailVisible = val;
    }

    private readonly MINUTES = 60;
    private readonly HOURS = this.MINUTES * 60;
    private readonly DAYS = this.HOURS * 24;
    private readonly WEEKS = this.DAYS * 7;

    get FormattedReminderTime(): string {
        if (!this.ReminderEnabled || this.ReminderSeconds === 0) {
            return;
        }
        if (this.ReminderSeconds % this.WEEKS === 0) {
            const time = this.ReminderSeconds / this.WEEKS;
            return `${time} ${BaseModel.t("week", { count: time })}`;
        } else if (this.ReminderSeconds % this.DAYS === 0) {
            const time = this.ReminderSeconds / this.DAYS;
            return `${time} ${BaseModel.t("day", { count: time })}`;
        } else if (this.ReminderSeconds % this.HOURS === 0) {
            const time = this.ReminderSeconds / this.HOURS;
            return `${time} ${BaseModel.t("hour", { count: time })}`;
        } else {
            const time = this.ReminderSeconds / this.MINUTES;
            return `${time} ${BaseModel.t("minute", { count: time })}`;
        }
    }

    get ReminderSeconds() {
        return this._todo.ReminderSeconds;
    }

    set ReminderSeconds(val: number) {
        this._todo.ReminderSeconds = val;
    }

    get ReminderEnabled() {
        return this._todo.ReminderEnabled;
    }

    set ReminderEnabled(val: boolean) {
        this._todo.ReminderEnabled = val;
    }

    public toggleDetail = e => {
        this.set("DetailVisible", !this.get("DetailVisible"));
    };

    get IsBoundToMail(): boolean {
        return this._isBoundToMail;
    }

    set IsBoundToMail(val: boolean) {
        this._isBoundToMail = val;
    }
}
